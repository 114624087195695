import { FormEvent, useState } from "react";

export const MessageBox = ({
  onSubmit,
}: {
  onSubmit: (msg: string) => boolean | Promise<boolean>;
}) => {
  const [msg, setMsg] = useState("");
  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const clear = await onSubmit(msg);
    if (clear) {
      setMsg("");
    }
  };
  return (
    <form onSubmit={submit}>
      <input
        type="text"
        onChange={(e) => setMsg(e.currentTarget.value)}
        value={msg}
      />
      <input type="submit" />
    </form>
  );
};
