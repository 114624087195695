import React from "react";
import "./App.css";

import { ChakraProvider, Link } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Chat from "./chat";
const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {" "}
      <ChakraProvider>
        <Router>
          <div className="App">
            <header className="App-header">Magellanic Clouds</header>
            <div>
              <nav>
                <ul>
                  <li>
                    {" "}
                    <RouterLink to="/chat">Chat</RouterLink>
                  </li>
                  <li>
                    <RouterLink to="/">Home</RouterLink>
                  </li>
                </ul>
              </nav>
            </div>
            <div>
              <Switch>
                <Route path="/chat">
                  <Chat />
                </Route>
                <Link href="https://dnd.magellanicclouds.net">
                  DND Character Manager
                </Link>
              </Switch>
            </div>
          </div>
        </Router>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
