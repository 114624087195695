import { useState } from "react";
import { useQuery } from "react-query";
import { MessageBox } from "./components/message-box";
type message = { timeStamp: number; text: string };

const sendMessage = (message: string) => {
  return fetch("/api/chat/send", {
    method: "post",
    headers: {
      "content-type": "application/json",
      accept: "application-json",
    },
    body: JSON.stringify({ message }),
  }).then((res) => res.ok);
};

const getMessages = (timeStamp = 0) => () =>
  fetch(
    "/api/chat/messages?timeStamp=" +
      timeStamp +
      "&rand=" +
      Math.random().toString().replace(".", ""),
    {
      method: "GET",
      headers: { "accept-type": "application/json" },
    }
  )
    .then((res) => res.json())
    .then((json) => json.data.messages);

const useMessages = (): message[] => {
  const [messages, setMessages] = useState<message[]>([]);
  const timeStamp =
    messages.length === 0 ? 0 : messages[messages.length - 1].timeStamp;
  const query = useQuery({
    initialData: [],
    refetchInterval: 3000,
    queryFn: getMessages(timeStamp),
    onSuccess: (data) => setMessages([...messages, ...data]),
  });
  return messages;
};
export default function Chat() {
  const messages = useMessages();
  return (
    <div>
      <div className="message-list">
        {messages?.map((msg) => (
          <div>
            {new Date(msg.timeStamp).toLocaleTimeString() + ": " + msg.text}
          </div>
        ))}
      </div>
      <MessageBox onSubmit={sendMessage} />
    </div>
  );
}
